<template>
    <div class="error-404">
        <h2>404: Oops!</h2>
        <p>We have a lot of things, just not this page. Sorry!</p>

        <div>
            <div class="link" @click="goBack">Go Back</div>
        </div>

        <div class="w-full max-w-xl">
            <img src="/images/404.svg" alt="404" class="w-full h-auto">
        </div>
    </div>
</template>
<script>

export default{
    data(){
        return{
            path: ''
        }
    },
    methods: {
        goBack(){
            if(this.path.length>1){
                this.$router.push({path: this.path})
            }
            else this.$router.go(-1)
        }
    },
    beforeRouteEnter(to, from, next) {
    next((vm) => {
        vm.path = from.path? from.path: "home" ;
    });
  },
}
</script>
<style lang="scss" scoped>
.error-404{
    @apply w-full h-full flex flex-col space-y-6 items-center pt-20;

    h2{
        @apply text-4xl text-theme-secondary font-semibold;
    }

    p{
        @apply text-2xl text-theme-dark font-semibold;
    }

    .link{
        @apply inline-block text-base text-white bg-theme-secondary px-8 py-3 mt-4 mb-10 cursor-pointer;
    }
}
</style>